<template>
    <div class="container-fluid">
        <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t('general.equipos') }}/{{ $t('general.marcas') }}</h1>
            </div>            
            </div>
        </div><!-- /.container-fluid -->
        </section>
        <section class="content">
            <div class="row">                
                <div class="col-md-12">
                    <div  class="card card-default card-tabs">
                        <div class="card-header p-1 pt-1">
                            <ul class="nav nav-pills">
                                <li class="nav-item"><a class="nav-link active" href="#equipos" data-toggle="tab">{{ $t('general.equipos') }}</a></li>  
                                <li class="nav-item"><a class="nav-link" href="#marcas" data-toggle="tab">{{ $t('general.marcas') }}</a></li>
                            </ul>
                        </div>
                    </div>
                    <br>
                    <div class="tab-content">
                        <div class="active tab-pane" id="equipos">
                            <equipos/>
                        </div>
                        <div  class="tab-pane" id="marcas">
                            <marcas/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import Equipos from './equipos/Equipos.vue'
import Marcas from './marcas/Marcas.vue'
export default {
    
    components: {
       'equipos': Equipos,
       'marcas':Marcas
    },
    data() {
        return {
        }
    },
    methods: {
       
    },
    watch:
    {
          
    },
    mounted() { 
        
    },
}
</script>
